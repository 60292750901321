<template>
  <v-container class="fill-height d-flex justify-center">
    <v-card elevation="10" class="mx-auto" width="500" :loading="loading">
      <v-img height="250" :src="immLogo"></v-img>
      <v-card-title>Queue Reservation Result</v-card-title>
      <v-card-subtitle
        >*please print or screenshot this page for your records</v-card-subtitle
      >
      <div id="printMe">
        <v-list>
          <v-subheader>Queue Reservation Result</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>QUEUE ID</v-list-item-subtitle>
              <v-list-item-title>
                <h1>Q-{{ queueId }}</h1>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>DATE</v-list-item-subtitle>
              <v-list-item-title>
                <h1>{{ queueDate }}</h1>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>TIME</v-list-item-subtitle>
              <v-list-item-title>
                <h1>{{ queueTime }}</h1>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <v-card-actions>
        <v-btn plain @click="print">
          <v-icon>mdi-printer</v-icon>
          PRINT
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn plain to="/">
          <v-icon>mdi-home</v-icon>
          HOME
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import immlogo from "../../assets/card_img1.png";
export default {
  data() {
    return {
      queueId: "",
      queueDate: "",
      queueTime: "",
      loading: false,
      immLogo: immlogo
    };
  },
  created() {
    this.queueId = this.$route.query.id ?? "";
    this.getQueueData();
  },
  watch: {
    "$route.query.id"(v, ov) {
      if (v == "" || v == ov) return;

      this.queueId = v;
      this.getQueueData();
    }
  },
  methods: {
    getQueueData() {
      if (this.queueId == "") return;
      const url =
        process.env.VUE_APP_BASE_URL + `/api/queue_result/${this.queueId}`;
      var config = {
        method: "get",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      };
      this.loading = true;
      this.axios(config)
        .then(res => {
          this.loading = false;
          // console.log(res.data);
          this.queueDate = res.data.queueDate;
          this.queueTime = res.data.queueTime;
        })
        .catch(() => {
          this.queueId = "ไม่พบข้อมูล";
          this.queueDate = "";
          this.queueTime = "";
        })
        .finally(() => (this.loading = false));
    },
    print() {
      this.$htmlToPaper("printMe");
    }
  }
};
</script>

<style></style>
